import React from "react";

function Home() {
  return (
    <div id="content">
      <h3 style={{ "text-align": "center", "margin-top": "100px" }}>
        Swift Analytics has moved to{" "}
        <a href="https://analytics.sirclo.com/login">analytics.sirclo.com</a>
      </h3>
    </div>
  );
}

export default Home;
