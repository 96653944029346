import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import Home from "./pages/home";

function App() {
  return (
    <BrowserRouter>
      <div class="container-fluid h-100" id="content">
        <div>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="*" render={() => <Redirect to="/" />} />
          </Switch>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
